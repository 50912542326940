* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background-color: #f3f3f3;
	height: 100%;
}

ul,
li,
dl {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

a {
	text-decoration: none;
	color: inherit;
	display: inline-block;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
}

p {
	margin: 0;
	padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.container {
	width: 1169px;
	margin: 0 auto;
	padding: 0;
}
h2 {
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 57px;
	color: #0b0725;
}

@media screen and (max-width: 1170px) {
	section {
		margin-top: 60px !important;
	}
	.container {
		width: 720px;
	}
	h2 {
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 38px;
	}
}

@media screen and (max-width: 720px) {
	.container {
		width: 100%;
		padding: 0 20px;
	}
	section {
		margin-top: 35px !important;
	}
}
