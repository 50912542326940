@import '../../style/colors.scss';
@import '../../style/JuraFont.scss';

footer {
	margin-top: 80px;
	width: 100%;
	background: $purple;
	border-radius: 25px 25px 0px 0px;
	padding-bottom: 50px;
}
.footer_logo {
	padding-top: 25px;
	display: flex;
	align-items: center;
	max-width: 333px;
	margin: 0 auto;
	h3 {
		font-style: normal;
		font-weight: 700;
		font-size: 40px;
		line-height: 47px;
		color: #ffffff;
	}
}
.footer_contacts {
	margin-top: 25px;
	display: flex;
	justify-content: space-between;
}
.footer-item {
	display: flex;
	align-items: center;
	span {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 21px;
		color: #ffffff;
		width: 12rem;
		margin-left: 10px;
	}

	.phone_info {
		align-items: center;
		.phone_call-footer {
			font-size: 16px;
			line-height: 19px;
			text-decoration-line: underline;
			color: #ffffff !important;
			text-align: center;
			display: block;
			width: 170px;
		}
	}
}

@media screen and (max-width: 720px) {
	.footer_logo {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.footer_contacts {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		grid-gap: 25px;
	}
}
